import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoSancor from "../../assets/old/img/logos-ase/ico-sancor.png";

export default () => (
    <SiniestrosLayout subCompaniaActivo="sancor">
        <Helmet>
            <title>Sancor Seguros - Siniestros</title>
            <meta
                name="description"
                content="Servicio de asistencia Sancor Seguros: 0800 777 4643."
            />
        </Helmet>

        <hr className="mb-4" />
        {/* Quotation */}
        <div className="card blog-card mb-2">
            <div className="card-body px-sm-5 py-5 bg-secondary">
                <img
                    className="float-md-right pb-2"
                    src={icoSancor}
                    alt="Sancor Seguros"
                />
                <h4 className="lead post-title text-muted">Sancor Seguros</h4>
                <p className="">Servicio de asistencia: 0800 777 4643.</p>
            </div>
        </div>
        <p className="pt-5 pb-3">
          Ante un siniestro automotor, te solicitamos realices la denuncia correspondiente de manera inmediata, pudiendo hacerlo por cualquiera de estas vías: línea gratuita 0 800 777 4643 disponible las 24 hs. los 365 días del año; con tu Productor Asesor de Seguros; a través de nuestro sitio de autogestión.
        </p>
        <p>
        Datos necesarios: Obtené y suministrá a esta aseguradora toda la información posible sobre tu siniestro y sus consecuencias: dónde, cuándo y cómo ocurrió el acontecimiento.
        </p>
        <p>
          Documentación y firma: Una vez efectuada la denuncia, debés firmar la misma y entregar la documental que corresponda. Podés hacerlo en nuestro sitio de autogestión.
        </p>

        <hr className="pb-4" />



    </SiniestrosLayout>
)
